import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
import PropTypes from "prop-types";
import M from "materialize-css/dist/js/materialize.min.js";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const RegisterationModal = () => {
  useEffect(() => {
    var elems = document.querySelectorAll("select");
    M.FormSelect.init(elems, {});
  }, []);

  const [Research, setResearch] = useState({
    name: "",
    InitPrice: 0,
    price: 0,
    content: 0,
  });

  const [image, setImage] = useState("");
  const [filename, setFilename] = useState("Choose File");
  const onChange = (e) => {
    setResearch({ ...Research, [e.target.name]: e.target.value });
  };

  const onChangefile = (e) => {
    setImage(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };
  const onChangeSelect = (selected) => {
    setResearch({ ...Research, category: selected.value });
  };

  const { name, InitPrice, price, category, content } = Research;
  const onSubmit = () => {
    if (name === "" || filename === "Choose File") {
      M.toast({ html: "Please enter the name and   Image" });
    } else {
      // addProduct(image, name, InitPrice, price, category, content);

      M.toast({ html: "تمت اضافة الدورة بنجاح" });
    }
  };

  return (
    <div id="register" className="modal" style={{ direction: "rtl" }}>
      <div className="modal-content">
        <h4 className="center">ارسال الملخص</h4>
        <br />
        <div className="file-field input-field">
          <div className="btn">
            <span>File</span>
            <input type="file" onChange={onChangefile} />
          </div>
          <div className="file-path-wrapper">
            <input
              className="file-path validate"
              type="text"
              // eslint-disable-next-line
              value={filename}
            />
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              // name="name" value={name} onChange={onChange}
            />
            <label htmlFor="firstName" className="active">
              عنوان الورقة العلمية
            </label>
          </div>
        </div>
        <div className="row">
          <div class="input-field">
            <select>
              <option value="" disabled selected>
                اختر محور الورقة
              </option>
              <option value="المحور الأول: التدريب الإلكتروني">
                المحور الأول: التدريب الإلكتروني.
              </option>
              <option value="المحور الثاني: التحول الرقمي والتنمية المستدامة">
                المحور الثاني: التحول الرقمي والتنمية المستدامة.
              </option>
              <option
                value="
                المحور الثالث: دور التعليم الإلكتروني داخل المؤسسات التعليمية
                والمراكز البحثية."
              >
                المحور الثالث: دور التعليم الإلكتروني داخل المؤسسات التعليمية
                والمراكز البحثية.
              </option>
              <option value="المحور الرابع: نظام الإدارة الإلكترونية.">
                المحور الرابع: نظام الإدارة الإلكترونية.
              </option>
              <option value=" المحور الخامس: الاقتصاد الرقمي في عصر المعرفة.">
                المحور الخامس: الاقتصاد الرقمي في عصر المعرفة.
              </option>
              <option value="المحور السادس: تجارب ناجحة في تطبيق التحول الرقمي.">
                المحور السادس: تجارب ناجحة في تطبيق التحول الرقمي.
              </option>
            </select>
            <label>المحاور</label>
          </div>
        </div>
        <div className="row ">
          <div className="input-field">
            <input
              type="text"
              // name="name" value={name} onChange={onChange}
            />
            <label htmlFor="firstName" className="active">
              اسم المشارك الذى سيعرض الورقة
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              // name="name" value={name} onChange={onChange}
            />
            <label htmlFor="firstName" className="active">
              الدولة *
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              // name="name" value={name} onChange={onChange}
            />
            <label htmlFor="firstName" className="active">
              الجهة التابع لها المشارك ( جامعة- كلية / مركز بحثى / الخ) *
            </label>
          </div>
        </div>{" "}
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              //  name="name" value={name} onChange={onChange}
            />
            <label htmlFor="firstName" className="active">
              لمؤهل العلمى / التخصص
            </label>
          </div>
        </div>{" "}
        <div className="row">
          <div className="input-field">
            <input
              type="text"
              // name="name"
              //  value={name}
              //   onChange={onChange}
            />
            <label htmlFor="firstName" className="active">
              الدرجة العلمية *
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input type="text" />
            <label htmlFor="firstName" className="active">
              البريد الالكترونى *
            </label>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <a
          href="#!"
          onClick={onSubmit}
          className="modal-close waves-effect blue waves-light btn"
        >
          اضافة
        </a>
      </div>
    </div>
  );
};

RegisterationModal.propTypes = {
  // addProduct: PropTypes.func.isRequired,
  // getDirectories: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  // sections: state.category.categories,
});

export default RegisterationModal;
