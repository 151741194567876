import React, { Fragment, useEffect } from "react";
import "./homepage.style.css";
import Timer from "../components/timer/timer";
import M from "materialize-css/dist/js/materialize.min.js";
import RegisterationModal from "../components/models/RegisterationModal";

const HomePage = () => {
  useEffect(() => {
    var elems = document.querySelectorAll(".collapsible");
    M.Collapsible.init(elems, {});
    var modal = document.querySelectorAll(".modal");
    M.Modal.init(modal, {});
  }, []);
  return (
    <Fragment>
      <section className="showcase">
        <div className="video-container">
          <video src="/img/1.mp4" autoPlay muted loop />
        </div>
        <div className="content">
          <span>
            <img
              src="/img/logo.png"
              alt="logo"
              width="150"
              style={{ opacity: "0.7" }}
            />
          </span>
          <h4>المؤتمر الدولي الاول (الافتراضي)</h4>
          <h2>
            {" "}
            {"     "}
            بعنوان : التحول الرقمي في عصر المعرفة{"   "}( التحديات , الانعكاسات
            )
          </h2>

          <h3>
            <Timer />
          </h3>
          <br />
          <h4> إجمالي الملخصات المستلمة 0 عدد الملخصات المقبولة 0</h4>
        </div>
      </section>
      <section id="about" className="container ">
        <h3 style={{ marginTop: "50px" }}>تمهيد</h3>
        <p style={{ fontSize: "1.3rem", lineHeight: "2.4rem" }}>
          يتغلل العالم الرقمي في شتى المجالات الحياتية بشكل متزايد فقد أصبحت
          تكنولوجيا المعلومات تستخدم تدريجيا لتوصيل المعرفة والمهارات بطرق جديدة
          ومبتكرة ، يقترن هذا التغلل بتغيرات مستقبلية في أسلوب العمل ونمطه ،
          اللذين يتأثران نفسيهما بالمناخ الحالي من انعدام اليقين الاقتصادي ،
          فضلا عن التحولات السياسية والأمنية والصحية ، فكان لزاما البحث والتفسير
          عن الاسباب والإشكاليات وكان هذا المؤتمر الدولي الافتراضي من اجل ايجاد
          حلولا لهذه الأشكاليات التي تعاني منها المنظمات والمجتمعات في العالم
          اجمع الذي اصبح متاحا بشكل فعال وسريع للاستفاذة من كل التجارب والخبرات
          المحلية والدولية في ظل تكنولوجيا المعلومات والمعرفة .
        </p>
        <h5 style={{ lineHeight: "3rem" }}>
          قواعد وشروط النشر بالمجلة ستنشر المداخلات والورقات البحثية المحكمة
          والمستوفية للشروط في عدد خاص بالمؤتمر وحسب الشروط التالية :
          <a class="waves-effect  modal-trigger teal-text" href="#modal1">
            اضغط هنا
          </a>
        </h5>

        <div id="modal1" className="modal" style={{ width: "80%" }}>
          <div class="modal-content">
            <h4>قواعد وشروط النشر </h4>
            <ol>
              <li>أن يسهم البحث في تعميق المعرفة ضمن محاور المؤتمر</li>
              <li>
                أن يكون البحث مستوفيا لشروط البحث العلمي, بما في ذلك ملخص البحث
                وعرض المشكلة ومراجعة الأدبيات ذات العلاقة والمنهجية المتبعة و
                تحليل البيانات وعرض النتائج والتوصيات .
              </li>
              <li>تقبل البحوث باللغة العربية واللغة الانجليزية .</li>
              <li>إتباع القواعد اللغوية السليمة في كتابة البحث.</li>
              <li>
                ألا يكون البحث جزءاً من رسالة الماجستير أو الدكتوراه التي أعدها
                الباحث, وألا يكون قد سبق نشره , أو تم إرساله للنشر في مجلة اخرى
                وألا يكون قد تمت المشاركة به في مؤتمر أو ندوة أو ورشة عمل.
              </li>
              <li>
                أن يكون البحث مكتوبا على ورق حجم A4 بحيث لا يقل حجم البحث عن 15
                صفحة و لا يزيد على 25 صفحة.
              </li>
              <li>
                تخضع البحوث المقدمة إلى المجلة للفحص والتقييم العلمي حسب الأصول
                المتبعة ، وتتحدد صلاحيتها للنشر بناء على رأي المحكمين بها.
              </li>
              <li>
                {" "}
                يلتزم الباحث بإجراء تعديلات المحكمين بالمؤتمر على بحثه وفق
                التقارير المرسلة إليه، وموافاة المجلة بنسخة معدلة خلال مدة لا
                تزيد عن (15) يوماً .
              </li>
              <li>
                لا يجوز للباحث أن يطلب عدم نشر بحثه بعد إرساله للتحكيم إلا
                لأسباب تقتنع بها هيأة التحرير.
              </li>
              <li>
                {" "}
                يرفق بالبحث ملخص باللغتين العربية والانجليزية بما لا يزيد عن
                (200) كلمة، وتلي الملخص كلمات مفتاحية دالة لا تزيد عن (7 )
                للتعريف بنواحي البحث الرئيسة.
              </li>
              <li>
                يحق لهيأة التحرير - إذا رأت ضرورة لذلك - إجراء بعض التعديلات على
                البحث من دون المساس بمضمونه وبما يتوافق مع أسلوب المجلة في النشر
                .
              </li>
              <li>
                {" "}
                يكتب الباحث على ورقة منفصلة اسمه بالكامل ووظيفته الحالية ودرجته
                العلمية واسم المؤسسة التي يعمل بها وعنوانه مفصلاً وأرقام الهواتف
                والبريد الالكتروني، ويرفق مع البحث سيرة ذاتية مختصرة للتعريف
                بالباحث.
              </li>
              <li>
                يجب إثبات المصادر والمراجع في متن البحث بحيث يوضع اسم ولقب
                الباحث والسنة بين قوسين، وفي حالة الاقتباس المباشر يضاف رقم
                الصفحة.
              </li>
              <li>
                جميع المراجع المشار إليها في متن البحث يجب أن تدرج في قائمة
                المراجع في نهاية البحث قبل الملاحق – إن وجدت – وترتب ترتيباً
                هجائياً مبتدأه بالمراجع العربية أولاً ثم المراجع الأجنبية دون
                ترقيمها، وذلك وفقاً لما يلي
                <ul>
                  <li>
                    {" "}
                    الكتب : اسم المؤلف أو المؤلفين ( السنة )، عنوان الكتاب ، اسم
                    المحقق أو المترجم إن وجد ، (رقم الطبعة ؛ مكان النشر: اسم
                    الناشر ) ، رقم الجزء . وفي حالة المراجع الأجنبية يكتب لقب
                    المؤلف أو المؤلفين أولاً ثم بقية الاسم.
                  </li>
                  <li>
                    {" "}
                    البحث أو المقال المنشور في الدورية : اسم الكاتب أو الكتّاب (
                    السنة) ، عنوان البحث أو المقال بين علامتي تنصيص ، اسم
                    الدورية ، الجهة التي تصدر عنها الدورية، واسم البلد الصادرة
                    عنها ، رقم المجلد ، رقم العدد . وفي حالة المراجع الأجنبية
                    يكتب لقب الكاتب أو الكتّاب أولاً ثم بقية الاسم.
                  </li>
                  <li>
                    الرسائل الجامعية : اسم مقدم الرسالة ( السنة) ،عنوان الرسالة
                    أو الأطروحة، اسم الجامعة المانحة للرسالة.
                  </li>
                  <li>
                    {" "}
                    مصادر ومراجع الانترنت : اسم المؤلف أو المؤلفين (السنة)
                    ،عنوان الكتاب أو المقال أو البحث ، اسم الموقع وعنوانه ،
                    وتاريخ زيارة الموقع .
                  </li>
                </ul>
              </li>
              <li>لا ينشر للباحث أكثر من بحث واحد في كل عدد.</li>
              <li>
                يعتبر البحث المنشور ملكاً للمجلة وبالتالي لا يجوز إعادة نشر أي
                بحث منشور أو تم قبوله للنشر بأي شكل من الأشكال أو بأي لغة في أي
                جهة أخرى، إلا بإذن رسمي من رئيس هيأة التحرير.
              </li>
              <li>
                تكون النواحي الفنية في أعداد البحث على النحو التالي :
                <ul>
                  <li>
                    {" "}
                    تكون الكتابة للبحوث العربية بالخط (Simplified Arabic ) ،
                    وللبحوث باللغة الانجليزية بالخط ( Times New Roman ) مع ترك
                    مسافة مفردة بين الأسطر.
                  </li>
                  <li>
                    أن يكون حجم الخط على النحو التالي:
                    <ol>
                      <li> 18 (داكن) للعناوين الرئيسة .</li>
                      <li>16 (داكن) للعناوين الفرعية.</li>
                      <li>
                        14 للمتن باللغة العربية و 12 للمتن باللغة الانجليزية.
                      </li>
                      <li>
                        12 مائل للملخص باللغة العربية و 10 للملخص باللغة
                        الانجليزية.
                      </li>
                      <li>10 للشرح أو التوضيح أسفل الصفحة.</li>
                    </ol>
                  </li>
                  <li>
                    أن تكون الهوامش على النحو التالي :
                    <ol>
                      <li>أعلى وأسفل 2.5 سم .</li>
                      <li>أيمن 3 سم .</li>
                      <li>أيسر 2.5 سم.</li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                تسلم البحوث والدراسات وجميع المراسلات المتعلقة أو بالبريد
                الالكتروني وحسب التطبيقات المخصصة لاستقبال البحوث حسب المرفق
                بإعلانات المؤتمر .
              </li>
            </ol>
            <p className="center-align">
              مجلة كلية الاقتصاد – جامعة الزاوية ، الزاوية ليبيا .
            </p>
          </div>
          <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">
              اغلاق
            </a>
          </div>
        </div>

        <h3 style={{ marginTop: "50px" }}>أهداف المؤتمر</h3>
        <ol>
          <li>التأكيد على أهمية التحول الرقمي في مختلف المجالات.</li>
          <li> تشجيع البحث العلمي في مجال التحول الرقمي .</li>
          <li>
            {" "}
            إبراز أهمية التحول الرقمي في تحقيق متطلبات التنمية المستدامة.
          </li>
          <li>
            {" "}
            تفعيل دور التعليم الإلكتروني للرفع من كفاءة ذوي الاحتياجات الخاصة،
            مع مراعاة الفروق الفردية بينهم.
          </li>
          <li>
            {" "}
            الرفع من مهارات الأداء لتحقيق متطلبات الجودة داخل المؤسسات التعليمية
            والمراكز البحثية.
          </li>
          <li>
            {" "}
            السعي نحو تطبيق تكنولوجيا المعلومات في مختلف المجالات الاقتصادية
            (الاقتصاد الرقمي).
          </li>
          <li>
            تعزيز علاقات التواصل والتبادل الثقافي والمعرفة، على المستويين المحلي
            والدولي.
          </li>
        </ol>
        <h3 style={{ marginTop: "50px" }}>محاور المؤتمر</h3>
        <ul class="collapsible">
          <li>
            <div class="collapsible-header">
              <h6>
                <i
                  class="material-icons teal-text"
                  style={{ marginLeft: "15px" }}
                >
                  check
                </i>
                المحور الأول: التدريب الإلكتروني.
              </h6>
            </div>
            <div class="collapsible-body">
              <ol>
                <li>
                  {" "}
                  تحديد الاحتياجات التدريبية من خلال أساليب التقنية الحديثة.
                </li>
                <li>
                  {" "}
                  التدريب الإلكتروني وانعكاساته على الرفع من كفاءة ذوي
                  الاحتياجات الخاصة.
                </li>
                <li>
                  {" "}
                  دور التدريب الإلكتروني في تحسين جودة البرامج التدريبية.
                </li>
                <li>
                  {" "}
                  فاعلية التدريب الإلكتروني في تطوير الموارد البشرية داخل
                  المؤسسات (الحكومية، الخاصة).
                </li>
              </ol>
            </div>
          </li>
          <li>
            <div class="collapsible-header">
              <h6>
                <i
                  class="material-icons teal-text"
                  style={{ marginLeft: "15px" }}
                >
                  check
                </i>
                المحور الثاني: التحول الرقمي والتنمية المستدامة.{" "}
              </h6>
            </div>
            <div class="collapsible-body">
              <ol>
                <li> دور التحول الرقمي في تحسين التنمية البشرية. </li>
                <li> دور التحول الرقمي في تحسين التنمية الاقتصادية. </li>
                <li> دور التحول الرقمي في تحسين التنمية السياحية. </li>
                <li> دور التحول الرقمي في تحسين التنمية البيئية.</li>
              </ol>
            </div>
          </li>
          <li>
            <div class="collapsible-header">
              <h6>
                <i
                  class="material-icons teal-text"
                  style={{ marginLeft: "15px" }}
                >
                  check
                </i>
                المحور الثالث: دور التعليم الإلكتروني داخل المؤسسات التعليمية
                والمراكز البحثية.{" "}
              </h6>
            </div>
            <div class="collapsible-body">
              <ol>
                <li>
                  {" "}
                  استخدام الأساليب التقنية الحديثة في التعليم داخل المؤسسات
                  التعليمية.{" "}
                </li>
                <li> تطبيق التقنيات الحديثة في البحوث العلمية.</li>
                <li>
                  {" "}
                  دور التعليم الإلكتروني في كفاءة التحصيل العلمي لذوي الاحتياجات
                  الخاصة.{" "}
                </li>
                <li>
                  {" "}
                  دور التعليم الإلكتروني في تحقيق متطلبات الجودة داخل المؤسسات
                  التعليمية والمراكز البحثية..
                </li>
              </ol>
            </div>
          </li>
          <li>
            <div class="collapsible-header">
              <h6>
                <i
                  class="material-icons teal-text"
                  style={{ marginLeft: "15px" }}
                >
                  check
                </i>
                المحور الرابع: نظام الإدارة الإلكترونية.
              </h6>
            </div>
            <div class="collapsible-body">
              <ol>
                <li>
                  {" "}
                  تحديات التحول نحو نظام الإدارة الإلكترونية والحوكمة
                  الالكترونية .
                </li>
                <li>
                  {" "}
                  دور الإدارة الإلكترونية في تحقيق الميزة التنافسية داخل مختلف
                  المؤسسات.
                </li>
                <li>
                  {" "}
                  فاعلية تطبيق نظام الإدارة الإلكترونية أثناء حدوث الأزمات.
                </li>
                <li>
                  {" "}
                  إمكانية إحلال الإدارة الإلكترونية محل الإدارة التقليدية في بعض
                  المجالات.
                </li>
              </ol>
            </div>
          </li>
          <li>
            <div class="collapsible-header">
              <h6>
                <i
                  class="material-icons teal-text"
                  style={{ marginLeft: "15px" }}
                >
                  check
                </i>
                المحور الخامس: الاقتصاد الرقمي في عصر المعرفة.
              </h6>
            </div>
            <div class="collapsible-body">
              <ol>
                <li> أهمية تطبيق الاقتصاد الرقمي في تحقيق النمو الاقتصادي.</li>
                <li>
                  {" "}
                  دور التحول الرقمي في تدفق المعلومات والأموال بين دول العالم.
                </li>
                <li>
                  {" "}
                  ملاءمة التشريعات القانونية لتوفير المناخ الملائم لتطبيق
                  الاقتصاد الرقمي.
                </li>
                <li> دور الاقتصاد الرقمي في جذب الاستثمارات الأجنبية.</li>
              </ol>
            </div>
          </li>
          <li>
            <div class="collapsible-header">
              <h6>
                <i
                  class="material-icons teal-text"
                  style={{ marginLeft: "15px" }}
                >
                  check
                </i>
                المحور السادس: تجارب ناجحة في تطبيق التحول الرقمي.
              </h6>
            </div>
            <div class="collapsible-body">
              <ol>
                <li> تجارب عربية.</li>
                <li> تجارب دولية.</li>
              </ol>
            </div>
          </li>
        </ul>
        <br />
      </section>
      <section id="supported" className="  grey lighten-5">
        <h3 className=" center-align">رعاة المؤتمر ...</h3>
        <br />
        <div className="row ">
          <div className="col m3 right">
            <img src="/img/112.png" width="250" />
          </div>
          <div className="col m3 right">
            {" "}
            <img src="/img/111.png" width="200" />
          </div>
          <div className="col m4"></div>
        </div>
      </section>

      {/* <!-- Fixed Action Button --> */}
      <div className="fixed-action-btn">
        <a
          href="#register"
          className="modal-trigger btn-floating btn-large teal lighten-2 pulse"
        >
          <i className="material-icons">note_add</i>
        </a>
        {/* modal */}
        <RegisterationModal />
      </div>
      <footer class="section teal darken-2 white-text center">
        <p class="flow-text">
          &copy; قسم الموقع الالكتروني جامعة الزاوية
          {"  "}
          {new Date().getFullYear()}
        </p>
      </footer>
    </Fragment>
  );
};

export default HomePage;
