import React, { Fragment } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "materialize-css/dist/css/materialize.min.css";
import M from "materialize-css/dist/js/materialize.min.js";

import HomePoge from "./pages/homepage";

function App() {
  return (
    <Fragment>
      <Router>
        <Switch>
          <Route path="/" component={HomePoge} />
        </Switch>
      </Router>
    </Fragment>
  );
}

export default App;
