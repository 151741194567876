import React, { useEffect, useState, Fragment } from "react";
import "./timer.style.css";

function App() {
  const calculateTimeLeft = () => {
    const difference = +new Date("2020-07-05") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span className="timer">
        {interval === "days" ? (
          <span>
            {timeLeft[interval]}
            {"  "}
            {"يوم"}
          </span>
        ) : null}
        {interval === "hours" ? (
          <span style={{ borderRight: "1px dotted white" }}>
            {timeLeft[interval]}
            {"  "}
            {"ساعات"}
          </span>
        ) : null}
        {interval === "minutes" ? (
          <span style={{ borderRight: "1px dotted white" }}>
            {timeLeft[interval]}
            {"  "}
            {"دقائق"}
          </span>
        ) : null}
        {interval === "seconds" ? (
          <span style={{ borderRight: "1px dotted white" }}>
            {timeLeft[interval]}
            {"  "}
            {"ثواني"}
          </span>
        ) : null}
      </span>
    );
  });

  return (
    <div>
      <h4>الموعد انعقاد المؤتمر بعد </h4>

      {timerComponents.length ? (
        timerComponents
      ) : (
        <span>انعقد المؤتمر بتاريخ : 05-07-2020</span>
      )}
    </div>
  );
}

export default App;
